/*
 * axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

import axios from 'axios';
import router from '../router'
import store from '../store'
import { Message } from 'element-ui'

// 请求环境
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = 'http://192.168.2.4:8085/';
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = 'https://platform.xiaoyuanshenghuowang.com:8084/'
}

// 请求超时时间
axios.defaults.timeout = 10000;
axios.defaults.headers['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 请求头参数加上token
    const token = store.state.token
    token && (config.headers.token = token);
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 接口响应拦截器
axios.interceptors.response.use(
  // 浏览器状态码200~299
  response => {
    if (response.status === 200) {
      const { code, desc } = response.data
      if (code !== 100) {
        if (code === 999) {
          Message.error('会话已过期，请重新连接');
          router.replace({ path: '/' });
        } else {
          Message.error(desc)
          return Promise.reject(response);
        }
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.code == 'ECONNABORTED') {
      return Message.error('网络连接超时，请重试')
    }
    if (!error.response) {
      return Message.error('服务器错误，请联系管理员')
    }
    if (error.response.status) {
      switch (error.response.status) {
        // 404请求不存在                
        case 404:
          Message.error('网络请求不存在')
          break;
        // 其他错误，直接抛出错误提示                
        default:
          Message.error(error.response.data.message)
      }
      return Promise.reject(error.response);
    }
  }
);

export default axios
