import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from './request/http.js'
import api from './request/api.js'
import VueCompositionAPI from "@vue/composition-api"

Vue.prototype.$axios = axios
Vue.prototype.$api = api

Vue.use(VueCompositionAPI);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
