import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { title: "" },
  },
  // 用于商超与用户联系
  {
    path: "/users",
    name: "users",
    component: () => import("../views/UserTencentIM.vue"),
    meta: { title: "客服" },
  },
  // 用于供应商与商超联系
  {
    path: "/b2b",
    name: "b2b",
    component: () => import("../views/B2BTencentIM.vue"),
    meta: { title: "客服" },
  },
  // 用于联系总后台
  {
    path: "/systems",
    name: "systems",
    component: () => import("../views/TencentIM.vue"),
    meta: { title: "客服" },
  },
]

const router = new VueRouter({
  routes,
})

router.afterEach(to => {
  if (to.meta.title) document.title = to.meta.title
})

export default router
