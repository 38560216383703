import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    SDKAppID: null,
    userID: null,
    type: null,
    sid: null, // 供应商id
    ssid: null, // 商超id
    conversationID: null,
  },
  getters: {},
  mutations: {
    setSDKAppID(state, SDKAppID) {
      state.SDKAppID = SDKAppID
    },
    setUserID(state, userID) {
      state.userID = userID
    },
    setType(state, type) {
      state.type = type
    },
    setSid(state, sid) {
      state.sid = sid
    },
    setSSid(state, ssid) {
      state.ssid = ssid
    },
    setConversationID(state, conversationID) {
      state.conversationID = conversationID
    },
  },
  actions: {},
  modules: {},
})
